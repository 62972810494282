<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .questionnair-info {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      .content-title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-left: 49px;
        margin-top: 35px;
        margin-bottom: 16px;
      }
      .content-detail {
        color: #666666;
        font-size: 18px;
        margin-left: 49px;
        margin-right: 49px;
        line-height: 40px;
        span {
          color: #447df5;
          font-weight: bold;
        }
      }
      .hint-text {
        color: #333333;
        font-weight: bold;
      }
      .announcements {
        margin-top: 56px;
        margin-left: 49px;
        margin-right: 49px;
        color: #666666;
        font-size: 16px;
        line-height: 30px;
      }
      .content-btn {
        width: 496px;
        height: 68px;
        margin-top: 35px;
        margin-left: 48px;
        background-color: #447df5;
        border-radius: 4px;
        color: white;
        font-size: 24px;
        font-weight: bold;
      }
      .hint-title {
        color: #999999;
        font-size: 12px;
        margin-top: 19px;
        margin-left: 48px;
        margin-bottom: 19px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="questionnair-info">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
        :curPage="curPageName"
      ></questionnaire-header>
      <div class="content-info">
        <p class="content-title">问卷名称：{{ projectInfo.project_title }}</p>
        <p class="content-detail">金币数：<span>{{ projectInfo.coin }}</span> 金币</p>
        <p class="content-detail">即时奖励：<span>{{ projectInfo.pay_type==1 ? "是" : "否" }}</span></p>
        <p class="content-detail">问卷时长：<span>约 {{ projectInfo.validate_time | format }}</span></p>
        <!-- <p class="content-detail">难易度：<span>15%</span></p> -->
        <p class="content-detail hint-text">
          问卷要求：<span
            >请注意认真仔细答题，不要前后矛盾，开放题认真填写，否则将会影响信用值，谢谢配合。</span
          >
        </p>
        <p class="content-detail">
          在回答问卷过程中，请不要中断，避免失去获得金币的机会。
        </p>
        <div class="announcements">
          <p class="hint-text">注意事项：</p>
          <p v-for="(item, index) in projectTipslist" :key="index">{{ (index + 1) + '.' + item }}</p>
          <!-- <p>1. 回答过程中请不要点击浏览器的返回按钮。</p>
          <p>2. 部分问卷在国外而导致问卷加载缓慢时，请耐心等待。</p> -->
        </div>
        <button class="content-btn" @click="startClick">开始回答问卷</button>
        <p class="hint-title">{{hintTitle}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";
import { fetchProjectinfo, fetchProjectTask } from "@/api/commodity.js"
import { formatSeconds } from "@/utils/filters";

export default {
  name: "SurveyStartQuestion",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "调查中心>",
      curPageName: "马上参与",
      hintTitle: "请确认内容后点击<开始回答问卷>（之后请勿返回当前页面）",
      projectId: undefined,
      projectInfo: () => {},
      projectTipslist: [],
    };
  },
  filters: {
    format(value) {
      return formatSeconds(value)
    }
  },
  created() {
    this.projectId = this.$route.query.projectId
    if (this.projectId == undefined) {
      this.$router.push({ name: "Survey"});
      return
    }
    this.initData()
  },
  methods: {
    initData() {
      this.getProjectInfo()
    },
    async getProjectInfo() {
      try {
        let result = await fetchProjectinfo({
          member_id: this.userId,
          token: this.userToken, 
          project_id: this.projectId
        });
        this.projectInfo = result.project;
        if (this.projectInfo.project_tips != undefined) {
          this.projectTipslist = this.projectInfo.project_tips.split(',');
        }
      } catch (error) {
        console.log(error)
      }
    },
    /// 获取项目连接
    async getProjectlink() {
      try {
        let result = await fetchProjectTask({
          member_id: this.userId,
          token: this.userToken, 
          project_id: this.projectId,
          platform: 1
        });
        let link = result.customer_url 
        // if (link.indexOf("?") != -1) {
        //   link = link +"&qquid=" + result.qquid; 
        // }else {
        //   link = link +"?qquid=" + result.qquid; 
        // }
        // console.log(link);
        window.open(link, '_blank');
      } catch (error) {
        console.log(error)
      }
    },
    startClick: function() {
      this.getProjectlink()
    }
  },
};
</script>
